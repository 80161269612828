import React, { useEffect, useRef } from "react";
import { loadLottieWithAnimation } from "../../../lib/lottie";
interface Props {
  open: boolean;
  onClick?: () => void;
}
export const HeaderMobileNavMenu: React.FC<Props> = ({
  open,
  onClick
}) => {
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  useEffect(() => {
    loadLottieWithAnimation(() => import("../../../public/animations/menu.json")).then(([lottie, animationData]) => {
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
      animationRef.current = lottie.loadAnimation({
        container: containerRef.current,
        renderer: "svg",
        loop: false,
        autoplay: false,
        animationData: animationData,
        name: "loader"
      });
      if (open) {
        animationRef.current?.playSegments([12, 24], true);
      }
    });
  }, []);
  useEffect(() => {
    if (open) {
      animationRef.current?.playSegments([12, 24], true);
    } else {
      animationRef.current?.playSegments([32, 44], true);
    }
  }, [open]);
  return <button id="mobile_nav_menu" aria-expanded={open} aria-label="Navigation Menu" className=" inline-flex  h-11 w-11 items-center justify-center self-center rounded-md px-[10px] text-plum hover:bg-plum-4 hover:text-plum-80 focus:bg-plum-4 focus:outline-none lg:hidden" onClick={onClick} ref={containerRef} data-sentry-component="HeaderMobileNavMenu" data-sentry-source-file="HeaderMobileNavMenu.tsx" />;
};