import { useCallback, useEffect, useRef, useState } from "react";

/**
 * useScrollingUp custom react hook
 * @returns {boolean}
 */
const useScrollingUp = () => {
  const prevScroll = useRef(0);
  //if it is SSR then check you are now on the client and window object is available
  if (process.browser) {
    prevScroll.current = window.pageYOffset;
  }
  const [scrollingUp, setScrollingUp] = useState(false);
  const handleScroll = useCallback(() => {
    const currScroll = window.pageYOffset;
    const isScrolled = prevScroll.current > currScroll;
    setScrollingUp(isScrolled);
    prevScroll.current = currScroll;
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return scrollingUp;
};

export default useScrollingUp;
