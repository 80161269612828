import clsx from "clsx";
import React from "react";
interface Props {
  text: string;
  className?: string;
}
export const HeaderBadge: React.FC<Props> = ({
  text,
  className
}) => {
  return <span className={clsx("text-body-tiny flex h-6 flex-col justify-center whitespace-nowrap rounded-full bg-lavender px-2", className)} data-sentry-component="HeaderBadge" data-sentry-source-file="HeaderBadge.tsx">
      {text}
    </span>;
};