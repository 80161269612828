import { Dialog } from "@headlessui/react";
import clsx from "clsx";
import Image from "next/legacy/image";
import { useRouter } from "next/router";
import { Fragment, ReactNode, useEffect, useState } from "react";
import { useHeaderBGByScroll } from "../../lib/useHeaderBGByScroll";
import { BookADemo } from "./Header/BookADemo";
import { HeaderCTA } from "./Header/HeaderCTA";
import { HeaderLogo } from "./Header/HeaderLogo";
import { HeaderMobileNavMenu } from "./Header/HeaderMobileNavMenu";
import { HeaderNav } from "./Header/HeaderNav";
import { HeaderNavMobile } from "./Header/HeaderNavMobile";
import useScrollingUp from "./Header/onScrollUp";
import { HeaderItem } from "./Header/types";
const DEFAULT_HEADER_ITEMS: HeaderItem[] = [{
  title: "Business",
  href: "/employers",
  id: "solutions-header",
  subNavigation: [{
    id: "employers",
    title: "For Employers",
    text: "Help employees bring their whole selves back to work",
    href: "/employers"
  }, {
    id: "consultants",
    title: "For Consultants",
    text: "Proven solutions to meet your clients’ needs",
    href: "/consultants"
  }, {
    id: "life-insurance",
    title: "For Life Insurance",
    text: "Support beneficiaries beyond the claim",
    href: "/life-insurance"
  }, {
    id: "health-plans",
    title: "For Health Plans",
    text: "Give your members the care they deserve",
    href: "/healthplans"
  }],
  side: {
    text: "Extend support when it is needed most",
    href: "/demo",
    cta: "Request pricing"
  }
}, {
  id: "solutions",
  title: "Solutions",
  href: "/families",
  flattenDesktop: true,
  subNavigation: [{
    id: "families",
    title: "Families",
    href: "/families"
  }]
}, {
  title: "Resources",
  href: "/resources",
  id: "resources",
  subNavigation: [{
    id: "employer-resources",
    title: "Employer Resources",
    href: "/employer-resources",
    text: "How to build bereavement care into your company"
  }, {
    id: "knowledge-base",
    title: "Knowledge Base",
    text: "Support throughout the journey of loss",
    href: "/learn"
  }, {
    id: "product",
    title: "See the Experience",
    href: "/product",
    text: "How we leverage tech to support families"
  }, {
    id: "costofdying",
    title: "Cost of Dying Report",
    href: "/costofdying",
    text: "The numbers behind bereavement"
  }],
  side: {
    text: "Bring Empathy to your organization",
    href: "/demo",
    cta: "Request a demo"
  }
}, {
  title: "Company",
  href: "/about",
  id: "company",
  subNavigation: [{
    id: "about-us",
    title: "About Us",
    href: "/about"
  }, {
    id: "news-room",
    title: "In the News",
    href: "/news-room"
  }, {
    id: "careers",
    title: "Careers",
    href: "/careers",
    badge: "We're hiring!"
  }]
}];
const flattenedNav = (items: HeaderItem[]) => {
  return items.flatMap(item => item.flattenDesktop ? item.subNavigation : item);
};
interface HeaderProps {
  items?: HeaderItem[];
  cta?: HeaderCTA;
  logoHref?: string;
  ctaOverride?: ReactNode;
  open?: boolean; // For chromatic
  colorClassName?: string;
}
export const Header = (props: HeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const {
    showHeaderBG
  } = useHeaderBGByScroll();
  const scrollingUp = useScrollingUp();
  const items = props.items || DEFAULT_HEADER_ITEMS;
  const router = useRouter();
  const logoHref = props.logoHref || "/";
  const onMobile = router.query.mobile; // For showing FAQ in app

  const onRouteChanged = () => {
    setMenuOpen(false);
  };
  useEffect(() => {
    router.events.on("routeChangeComplete", onRouteChanged);
    return () => {
      router.events.off("routeChangeComplete", onRouteChanged);
    };
  }, [router]);
  return <>
      <nav role="navigation" key={router.asPath} className={clsx("sticky top-0 z-40 w-full transition-all duration-300 ease-out", {
      [props.colorClassName]: !showHeaderBG,
      "bg-beige shadow-sm": showHeaderBG,
      "bg-white": menuOpen,
      "top-0": scrollingUp,
      "-top-16 sm:-top-20": !scrollingUp,
      hidden: onMobile
    })}>
        <div className="mx-auto max-w-7xl px-4 lg:px-4">
          <div className={clsx("flex h-16 flex-1 justify-between sm:h-20")}>
            <div className="flex flex-1 flex-row justify-between px-2">
              <HeaderLogo href={logoHref} data-sentry-element="HeaderLogo" data-sentry-source-file="Header.tsx" />
              {items.length > 0 && <HeaderMobileNavMenu onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} />}
            </div>
            <div className="hidden pr-2 lg:ml-6 lg:flex lg:items-center">
              <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                {flattenedNav(items).map(item => <HeaderNav key={item.href} item={item} />)}
                <div>
                  {!!props.ctaOverride ? props.ctaOverride : <HeaderCTA cta={props.cta} />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <Dialog as="div" className="lg:hidden" open={menuOpen} static={props?.open} onClose={setMenuOpen} data-sentry-element="Dialog" data-sentry-source-file="Header.tsx">
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 top-0 right-0 z-50 w-full overflow-y-auto bg-white" data-sentry-element="unknown" data-sentry-source-file="Header.tsx">
          <div className="sticky top-0 flex h-16 flex-1 flex-row justify-between bg-white px-6 sm:h-20">
            <Image id="header-logo-mobile" className="block h-6 w-auto sm:h-8" src={"/images/empathy-logo.svg"} alt="empathy" width={128} height={32} data-sentry-element="Image" data-sentry-source-file="Header.tsx" />
            <HeaderMobileNavMenu onClick={() => setMenuOpen(!menuOpen)} open={menuOpen} data-sentry-element="HeaderMobileNavMenu" data-sentry-source-file="Header.tsx" />
          </div>
          <div className="mt-2 flow-root pb-32">
            {items.map(prop => prop.subNavigation ? <Fragment key={prop.id}>
                  <div className="text-h3 px-9 pb-2 pt-6">{prop.title}</div>
                  {prop.subNavigation.map(subNavigation => <HeaderNavMobile key={subNavigation.href} item={subNavigation} />)}
                </Fragment> : <HeaderNavMobile key={prop.href} item={prop} />)}
          </div>
          <div className="fixed bottom-0 left-0 right-0 bg-white py-6 px-9 shadow shadow-plum-40">
            <BookADemo className="mx-auto" variant="chunky-dark" data-sentry-element="BookADemo" data-sentry-source-file="Header.tsx"></BookADemo>
          </div>
        </Dialog.Panel>
      </Dialog>
    </>;
};