import { useCallback } from "react";
import { reportDemoLead } from "../../../lib/customerIO";
import { getCompanionBaseURL } from "../../../lib/util";
import { Button } from "../../Button";
import { usePageLayoutActions } from "../PageLayoutContext";
const DEFAULT_CTA = "Book a Demo";
const DEFAULT_CTA_ID = "book_a_demo";
export type HeaderCTA = null | {
  text: string;
  action: "book-demo" | "download-app";
  id?: string;
} | {
  text: string;
  onClick: () => void;
  id?: string;
};
type HeaderCTAProps = {
  cta: HeaderCTA;
};
export const HeaderCTA: React.FC<HeaderCTAProps> = ({
  cta
}) => {
  const hasCTA = cta !== null;
  const ctaText = cta?.text || DEFAULT_CTA;
  const ctaId = cta?.id || DEFAULT_CTA_ID;
  const {
    showDownloadBanner,
    onBookDemo
  } = usePageLayoutActions();
  const ctaAction = useCallback(() => {
    if (cta) {
      if ("onClick" in cta) {
        cta.onClick();
        return;
      }
      if ("action" in cta) {
        switch (cta.action) {
          case "book-demo":
            reportDemoLead().finally(() => onBookDemo(ctaText));
            break;
          case "download-app":
            showDownloadBanner(ctaText);
            break;
        }
        return;
      }
    }
    reportDemoLead().finally(() => onBookDemo(ctaText));
  }, [ctaText, onBookDemo, cta, showDownloadBanner]);
  if (!hasCTA) return null;
  return <div className="flex flex-row gap-2" data-sentry-component="HeaderCTA" data-sentry-source-file="HeaderCTA.tsx">
      <a href={getCompanionBaseURL()}>
        <Button title="Login" id="sign-up-header" variant="bordered-white" data-sentry-element="Button" data-sentry-source-file="HeaderCTA.tsx" />
      </a>
      <Button title={ctaText} id={ctaId} variant="dark" onClick={ctaAction} className="min-w-[160px]" data-sentry-element="Button" data-sentry-source-file="HeaderCTA.tsx" />
    </div>;
};