import Link from "next/link";
import { HeaderBadge } from "./HeaderBadge";
import { HeaderItem } from "./types";
interface Props {
  item: HeaderItem;
}
export const HeaderNavMobile = ({
  item: {
    id,
    title,
    href,
    badge
  }
}: Props) => {
  return <Link prefetch={false} href={href} id={`mobile-${id}`} className={"flex h-10 flex-row items-center pl-9 pr-4 text-plum-80"} data-sentry-element="Link" data-sentry-component="HeaderNavMobile" data-sentry-source-file="HeaderNavMobile.tsx">
      {title}
      {badge && <HeaderBadge text={badge} className="ml-2" />}
    </Link>;
};