import throttle from "lodash/throttle";
import { useEffect, useRef, useState } from "react";

export const useHeaderBGByScroll = () => {
  const [showHeaderBG, setShowHeaderBG] = useState(false);

  const handleScroll = useRef(
    throttle(() => {
      setShowHeaderBG(window.scrollY > 10);
    }, 100),
  );

  useEffect(() => {
    const scrollHandler = handleScroll.current;
    window.addEventListener("scroll", scrollHandler);
    scrollHandler();
    return () => window.removeEventListener("scroll", scrollHandler);
  }, []);

  return { showHeaderBG };
};
