import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
export const HeaderLogo = ({
  href = "/",
  reload = false
}) => {
  const image = <Image id="header-logo-image" className="block h-6 w-auto sm:h-8" src={"/images/empathy-logo.svg"} alt="empathy" width={128} height={32} />;
  if (reload) {
    return <a id="header-logo" href={href} className="flex flex-shrink-0 items-center self-center py-2" aria-label="empathy home page">
        {image}
      </a>;
  }
  return <Link prefetch={false} href={href} id="header-logo" aria-label="empathy home page" className="flex flex-shrink-0 items-center self-center py-2" data-sentry-element="Link" data-sentry-component="HeaderLogo" data-sentry-source-file="HeaderLogo.tsx">
      {image}
    </Link>;
};