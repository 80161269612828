import { useRouter } from "next/router";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { clearTestUser, setTestUser } from "../lib/googleTagManager";
import { Button } from "./Button";
export const TEST_USER_COOKIE_NAME = "test-user";
export const TestUserLayout = () => {
  const [cookies, setCookie, removeCookie] = useCookies([TEST_USER_COOKIE_NAME]);
  const isTestUser = cookies[TEST_USER_COOKIE_NAME] === "true";
  const {
    query
  } = useRouter();
  useEffect(() => {
    if (query.testUser === "true") {
      if (!isTestUser) {
        setCookie(TEST_USER_COOKIE_NAME, true);
      }
      setTestUser();
    }
    return () => {
      clearTestUser();
    };
  }, [isTestUser, query, setCookie]);
  return isTestUser ? <div className="bg-frost py-4 pl-4">
      You are running the test user version of the website
      <Button className="ml-5" title="disable" variant="bordered" onClick={() => {
      removeCookie("test-user");
    }} />
    </div> : null;
};