import { Popover } from "@headlessui/react";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { PopoverDropdown } from "../../PopoverDropdown";
import { HeaderBadge } from "./HeaderBadge";
import { HeaderItem } from "./types";
import { TextWithArrow } from "../../Harmony/components/TextWithArrow";
export const HeaderNav = ({
  item: {
    id,
    title,
    href,
    subNavigation,
    side
  }
}: {
  item: HeaderItem;
}) => {
  const router = useRouter();
  const hasSubitems = !!subNavigation;
  const current = router.asPath === href;
  if (!hasSubitems) {
    return <Link prefetch={false} href={href} id={id} className={clsx("relative inline-flex items-center rounded px-1 pt-1 text-sm font-normal text-plum outline-none  focus:after:absolute focus:after:inset-0 focus:after:mb-1 focus:after:border-b-[1.5px] focus:after:border-plum-16 focus:after:content-['']", {
      "font-semibold ": current
    })}>
        {title}
      </Link>;
  }
  return <PopoverDropdown variant={current ? "bold" : "regular"} title={title} panelClassName="min-w-[200px] flex flex-row w-unset" sidebar={side && <div className="text-body-small w-[200px] rounded-r-lg  bg-lavender/50 p-8 pt-11">
            {side.text}
            <Link href={side.href} className="text-body-small mt-1 block font-medium hover:underline">
              <TextWithArrow text={side.cta} />
            </Link>
          </div>} data-sentry-element="PopoverDropdown" data-sentry-component="HeaderNav" data-sentry-source-file="HeaderNav.tsx">
      {subNavigation?.map(item => <Popover.Button as={Link} key={item.href} prefetch={false} href={item.href} id={item.id} className={clsx("-mx-8 flex flex-col p-3 px-8 hover:bg-plum-2", {
      "w-[300px]": !!side
    })}>
          <div className="flex flex-row items-center">
            <span className="">{item.title}</span>
            {item.badge && <HeaderBadge text={item.badge} className="ml-2" />}
          </div>
          <p className="text-body-small mt-1 mr-8 flex-1 whitespace-pre-wrap text-plum-64 empty:hidden">
            {item.text}
          </p>
        </Popover.Button>)}
    </PopoverDropdown>;
};