export const reportDemoLead = async () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const cioId = urlParams.get("cio_id");

  if (!cioId) return;

  await fetch("/api/cio-report-event-user-id", {
    method: "POST",
    body: JSON.stringify({ cioId: cioId }),
    headers: {
      Accept: "application/json",
    },
  });
};
