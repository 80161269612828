import Link from "next/link";
import { usePathname } from "next/navigation";
import React, { useEffect, useState } from "react";
const BANNER_LINK = "/lifevault";
export const TopBanner = () => {
  const [utms, setUtms] = useState({});
  const pathname = usePathname();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    setUtms({
      utm_source: queryParams.get("utm_source") || "website",
      utm_medium: queryParams.get("utm_medium") || "brand",
      utm_campaign: queryParams.get("utm_campaign") || "sticky-banner"
    });
  }, []);
  if (pathname === undefined || pathname === BANNER_LINK) {
    return <></>;
  }
  return <Link prefetch={false} passHref id="life-vault-banner" href={`${BANNER_LINK}?${new URLSearchParams(utms)}`} className="text-body-standard flex justify-center bg-plum py-4 text-white" data-sentry-element="Link" data-sentry-component="TopBanner" data-sentry-source-file="TopBanner.tsx">
      <span>
        Introducing<span className="lg:hidden">{":"}</span> Empathy LifeVault™
      </span>
      <span className="hidden lg:inline">
        —empowering families to create their estate plan for a secure financial
        future
        {". "}
        <span className="font-bold">&nbsp;&nbsp;Learn More</span>
      </span>
      <span className="tracking-normal">&nbsp;{"->"}</span>
    </Link>;
};