import { Button, ButtonProps } from "../../Button";
import { usePageLayoutActions } from "../PageLayoutContext";
import { reportDemoLead } from "../../../lib/customerIO";
export const BookADemo = ({
  className,
  variant = "dark"
}: {
  className?: string;
  variant?: ButtonProps["variant"];
}) => {
  const {
    onBookDemo
  } = usePageLayoutActions();
  return <Button onClick={() => reportDemoLead().finally(() => onBookDemo())} title="Book a Demo" id="book_a_demo" variant={variant} className={className || "self-center md:min-w-[160px] lg:px-10"} data-sentry-element="Button" data-sentry-component="BookADemo" data-sentry-source-file="BookADemo.tsx" />;
};