import { DownloadAppBadges } from "./DownloadAppBadges";
import { Modal } from "./Modal";
interface Props {
  visible: boolean;
  onClose: () => void;
}
export function DownloadAppBanner({
  visible,
  onClose
}: Props) {
  return <Modal visible={visible} onClose={onClose} title="Get started with Empathy" data-sentry-element="Modal" data-sentry-component="DownloadAppBanner" data-sentry-source-file="DownloadAppBanner.tsx">
      <div className="mx-auto flex h-full  flex-col items-center justify-center rounded-b-xl bg-white  py-14 px-6 md:py-20">
        <div className="text-e6 sm:text-e5 text-center ">
          Get started with Empathy
        </div>
        <p className="text-body-small mx-auto mt-2 max-w-sm text-center text-plum-80 ">
          Losing a loved one is hard enough — we’re right here to help with all
          the responsibilities and emotions that come with it.
        </p>
        <div className="mt-8 mb-4 block self-center">
          <DownloadAppBadges variant="dark" data-sentry-element="DownloadAppBadges" data-sentry-source-file="DownloadAppBanner.tsx" />
        </div>
      </div>
    </Modal>;
}