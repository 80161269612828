import { toFieldPath } from "@stackbit/annotations";
import React from "react";
interface Props {
  text: string;
  fieldPath?: string;
  className?: string;
}
export const TextWithArrow: React.FC<Props> = ({
  text,
  fieldPath,
  className
}) => {
  const spanProps = fieldPath ? toFieldPath(fieldPath) : null;
  return <div className={className} data-sentry-component="TextWithArrow" data-sentry-source-file="TextWithArrow.tsx">
      <span {...spanProps}>{text}</span>
      {"\u00A0"}
      <TinyArrow data-sentry-element="TinyArrow" data-sentry-source-file="TextWithArrow.tsx" />
    </div>;
};
export const TinyArrow: React.FC = () => {
  return <span className="whitespace-nowrap tracking-normal" data-sentry-component="TinyArrow" data-sentry-source-file="TextWithArrow.tsx">{`->`}</span>;
};